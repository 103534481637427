import { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react'
import { SparklesIcon, XIcon } from '@heroicons/react/solid'
import { Button, Group, Modal, TextInput, Tooltip, useModalsStack } from '@mantine/core'
import * as Sentry from '@sentry/react'
import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { groupBy, uniq, xorBy } from 'lodash'
import { usePostHog } from 'posthog-js/react'
import { useLocation, useNavigate } from 'react-router-dom'
import { MagicWand01, SearchMd } from 'untitled-icons'

import { ErrorFallback } from '@/components/ErrorFallback'
import { Loader, OscillatingLoading } from '@/components/Loader'
import { Diamond, File, Graph, IncreasingGraph } from '@/icons/home'
import { cn } from '@/util/classNames'
import { formatNumberCompact } from '@/util/formatNumber'
import { trpc } from '@/util/trpc'
import { FILTERS_MAP, FilterType, useTrackedFilterStore, useTrackedMIStore } from '../_hook'

export const FILTERS_EMOJI = {
  'Categories & Product Types': '🔸',
  Etailers: '©',
  'Price Range': '',
  ingredients: '🧪',
  'For Whom': '',
  brands: '©',
  effects: '🌟',
  attributes: '🌿',
  concerns: '😔',
  certificates: '',
  sizes: '',
  ratings: '',
} as const

interface AIFiltersContext {
  searchHome?: string
}

export const AIFiltersContext = createContext<AIFiltersContext>({} as AIFiltersContext)

export const AIFiltersProvider = ({
  searchHome,
  children,
}: {
  searchHome: string
  children: ReactNode
}) => {
  return (
    <AIFiltersContext.Provider value={{ searchHome: searchHome }}>
      {children}
    </AIFiltersContext.Provider>
  )
}

export const ModalContent = Sentry.withErrorBoundary(
  ({
    onClose,
    filterIds,
    setFilterIds,
  }: {
    onClose: () => void
    filterIds: FilterType[]
    setFilterIds: (filtersId: FilterType[]) => void
  }) => {
    const posthog = usePostHog()
    const { setFilters, geo } = useTrackedMIStore()
    const vars = useTrackedFilterStore()
    const [search, setSearch] = useState('')
    const [enabled, setEnabled] = useState(false)

    const [hoveredId, setHoveredId] = useState('')
    const scrollContainerRef = useRef<HTMLDivElement | null>(null)
    const navigate = useNavigate()
    const location = useLocation()
    const [navigateTo, setNavigateTo] = useState('/product-analysis')
    const [selected, setSelected] = useState('Product Catalog')

    const searchHome = useContext(AIFiltersContext).searchHome

    useEffect(() => {
      // The ID of the <li> you want to check
      // const targetId = 'target-li-id'
      const targetElement = document.getElementById(hoveredId)

      if (targetElement && scrollContainerRef.current) {
        const scrollContainer = scrollContainerRef.current

        const { top: elementTop, bottom: elementBottom } = targetElement.getBoundingClientRect()
        const { top: containerTop, bottom: containerBottom } =
          scrollContainer.getBoundingClientRect()

        // Check if the <li> is not fully in view within the scroll container
        if (elementTop < containerTop || elementBottom > containerBottom) {
          // Scroll the <li> into view
          targetElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
        }
      }
    }, [hoveredId]) // Empty dependency array means this runs once on mount

    useEffect(() => {
      if (searchHome) {
        setSearch(searchHome)
        setEnabled(true)
      }
    }, [searchHome])

    const { data, isLoading } = trpc.ecom_filters.ai_filters.useQuery(
      {
        ...vars,
        q: search,
      },
      {
        enabled,
        keepPreviousData: true,
        select(data) {
          return groupBy(
            data?.map((d) => ({
              id: `${d.id}`,
              type: d.id_type,
              name: d.name,
              reason: d.reason,
            })) ?? [],
            (o) => FILTERS_MAP[o.type]
          )
        },
      }
    )

    const { data: count, isLoading: isCountLoading } = trpc.ecom_filters.productCount.useQuery(
      {
        ...vars,
        filter_ids: filterIds.map(({ id, type }) => ({ id, type })),
      },
      {
        enabled: filterIds.length > 0,
      }
    )

    // const data = useMemo(
    //   () => groupBy(filterIds, (o) => FILTERS_MAP[o.type] || 'default'),
    //   [filterIds]
    // )

    const disabled = filterIds.length === 0 || count === 0 || isCountLoading

    const isInsightsPage =
      location.pathname.startsWith('/market-insights') ||
      location.pathname.startsWith('/product-insights') ||
      location.pathname.startsWith('/rankings') ||
      location.pathname.startsWith('/product-analysis')

    return !enabled ? (
      <div className="mx-auto -mt-10 flex min-h-[60dvh] max-w-[70%] flex-col justify-center space-y-4 text-center">
        <div className="mx-auto flex items-center justify-center space-x-1 text-center text-xs font-medium text-accent-600">
          <MagicWand01 className="-mt-1 size-4" />
          <p>AI filters</p>
        </div>
        <h1 className="text-lg font-medium">What are you looking for?</h1>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            setEnabled(true)
          }}
        >
          <TextInput
            value={search}
            onChange={(event) => setSearch(event.currentTarget.value)}
            placeholder="Search..."
            leftSection={<SearchMd className="ml-1 size-4" />}
          />
        </form>
      </div>
    ) : isLoading ? (
      <div className="mx-auto flex min-h-[60dvh] flex-col justify-center space-y-4 text-center">
        <div>
          <OscillatingLoading height={250} />
        </div>
        <p className="-mt-10 text-sm text-primary-500">eyva is looking for the best match</p>
      </div>
    ) : (
      <div className="grid grid-cols-2">
        <div
          className="max-h-[80dvh] min-h-[60dvh] space-y-4 overflow-y-auto rounded-2xl bg-primary-700 p-6 text-white [scrollbar-color:rgb(66_80_104)_rgb(66_80_104)] "
          ref={scrollContainerRef}
        >
          <div className="space-y-6">
            <h1 className="text-lg font-semibold">Understand our proposed options</h1>

            {uniq(Object.values(FILTERS_MAP)).map((filterType) => {
              const filters = data?.[filterType] ?? []
              if (filters.length > 0)
                return (
                  <div key={filterType} className="space-y-5">
                    <h2 className="font-semibold capitalize">
                      {FILTERS_EMOJI[filterType]} {filterType}
                    </h2>
                    <ul className="custom-disc ml-6 space-y-3 text-sm">
                      {filters.map((d) => {
                        return (
                          <li
                            key={d.id}
                            id={d.id}
                            className={cn(
                              'py-1 pr-2',
                              hoveredId === d.id && '!-ml-5 rounded-md bg-white pl-5 text-black'
                            )}
                          >
                            <span className="font-semibold">{d.name}:</span> {d.reason}
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                )
            })}
          </div>
        </div>
        <div className="flex max-h-[80dvh] min-h-[60dvh] flex-col justify-between space-y-4 overflow-y-auto px-5 pb-5 pl-9">
          <div className="relative space-y-4 ">
            <div className="sticky top-0 space-y-2 bg-white pb-4 pt-5">
              <div className="flex items-center justify-start gap-2">
                {/* <Stars className="size-5" /> */}
                <h1 className="text-lg font-semibold text-primary-900">Best Match!</h1>
              </div>
              <div className="flex items-center text-sm text-primary-500">
                {filterIds.length > 0 ? (
                  <>
                    {isCountLoading ? (
                      <Loader className="mr-2 inline-block size-4 border-2" />
                    ) : (
                      <p className="mr-1 font-medium text-accent-600">
                        {formatNumberCompact(count)}
                      </p>
                    )}
                    <p>
                      <span className="font-medium text-accent-600"> products found </span>
                      on e-tailers with your selection.
                    </p>
                  </>
                ) : (
                  <p>Select filters below that best match your preferences.</p>
                )}
              </div>
            </div>

            {uniq(Object.values(FILTERS_MAP)).map((filterType) => {
              const filters = data?.[filterType] ?? []
              if (filters.length > 0)
                return (
                  <div key={filterType} className="space-y-2">
                    <h2 className="font-semibold capitalize text-[#11131A]">{filterType}</h2>
                    <div className="-m-1 flex flex-wrap items-center">
                      {filters.map((d) => {
                        const isSelected = filterIds.some((o) => o.id === d.id)
                        return (
                          <button
                            key={d.id}
                            onMouseEnter={() => setHoveredId(d.id)}
                            onMouseLeave={() => setHoveredId('')}
                            onClick={() => {
                              setFilterIds(xorBy(filterIds, [d], (o) => o.id))
                            }}
                            className={cn(
                              'm-1 flex !h-[34px] items-center space-x-2 rounded-md border border-primary-200 bg-primary-50 px-3 py-2 text-xs text-primary-900 transition-all duration-500 hover:bg-primary-700 hover:text-white',
                              isSelected && ' border-primary-700 bg-primary-700 text-white'
                            )}
                          >
                            <span>{d.name}</span>
                          </button>
                        )
                      })}
                    </div>
                  </div>
                )
            })}
            {!isInsightsPage && (
              <>
                <div className="h-px w-full bg-primary-200" />
                <div className="space-y-4">
                  <p className="text-sm text-primary-500">
                    You’ll be directed to the Product Catalog by default, or you can choose another
                    feature.
                  </p>
                  <div className="grid grid-cols-2 gap-4">
                    {[
                      {
                        title: 'Product Catalog',
                        icon: <Diamond className="size-4 stroke-primary-900" />,
                        description: 'Browse, explore, and compare products from top e-tailers.',
                        onClick: () => {
                          setNavigateTo('/product-analysis')
                          setSelected('Product Catalog')
                        },
                      },
                      {
                        title: 'Market Insights',
                        icon: <Graph className="size-4 stroke-primary-900" />,
                        description: 'Analyze market trends and competitive landscape.',
                        onClick: () => {
                          setNavigateTo('/market-insights')
                          setSelected('Market Insights')
                        },
                      },
                      {
                        title: 'Product Insights',
                        icon: <File className="size-4 stroke-primary-900" />,
                        description: 'Detect benchmarks and assess your product.',
                        onClick: () => {
                          setNavigateTo('/product-insights')
                          setSelected('Product Insights')
                        },
                      },
                      ...(geo === 'de'
                        ? [
                            {
                              title: 'Product Performance',
                              icon: <IncreasingGraph className="size-4 stroke-primary-900" />,
                              description: 'Best-performing products on Douglas, DM and Mueller.',
                              onClick: () => {
                                setNavigateTo('/rankings')
                                setSelected('Product Performance')
                              },
                            },
                          ]
                        : []),
                    ].map((item) => (
                      <button
                        key={item.title}
                        onClick={item.onClick}
                        disabled={disabled}
                        className={cn(
                          'flex flex-col gap-2 rounded-lg border border-gray-200 p-4 text-left transition-all duration-500 hover:shadow-lg disabled:opacity-50',
                          selected === item.title && 'border-primary-700 bg-primary-50'
                        )}
                      >
                        <div className="flex items-center gap-2">
                          <span className="flex size-6 items-center justify-center rounded-[3px] bg-primary-200 text-primary-900">
                            {item.icon}
                          </span>
                          <h3 className="font-medium text-primary-900">{item.title}</h3>
                        </div>
                        <p className="text-xs text-primary-700">{item.description}</p>
                      </button>
                    ))}
                  </div>
                </div>
              </>
            )}
            <div className="space-y-3">
              <div className="flex space-x-2">
                <Button
                  variant="default"
                  onClick={() => {
                    setEnabled(false)
                  }}
                  h={40}
                  w={190}
                  className="border border-primary-200 bg-white text-sm font-normal text-primary-900"
                >
                  Try Again
                </Button>
                <Button
                  disabled={disabled}
                  onClick={() => {
                    setFilters((state) => {
                      state.filter_ids = filterIds
                    })
                    posthog?.capture('AI Filters', {
                      search,
                      filterIds,
                      country: vars.geo,
                    })
                    if (navigateTo && !isInsightsPage) {
                      navigate(navigateTo)
                    }
                    onClose()
                  }}
                  h={40}
                  w={190}
                  className="!px-4 !text-sm !font-medium"
                >
                  Apply search settings
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  },
  {
    fallback: <ErrorFallback title="AI Filters" />,
  }
)

const toolTipAtom = atomWithStorage('ai-filter-tooltip', true)

export const AIFiltersModal = ({
  children,
}: {
  children: (params: { open: () => void }) => ReactNode
}) => {
  const stack = useModalsStack(['select-filters', 'confirm-action'])
  const [filterIds, setFilterIds] = useState<FilterType[]>([])

  const onClose = () => {
    if (filterIds.length > 0) {
      stack.open('confirm-action')
    } else {
      stack.closeAll()
    }
  }
  return (
    <>
      <Modal.Stack>
        <Modal
          opened={stack.state['select-filters']}
          onClose={onClose}
          size={1244}
          centered
          withCloseButton={false}
          className="relative"
          p={0}
          radius={10}
        >
          <button className="absolute right-8 top-8" onClick={onClose}>
            <XIcon className="size-5 text-primary-500" />{' '}
          </button>
          <ModalContent
            onClose={() => {
              stack.closeAll()
            }}
            filterIds={filterIds}
            setFilterIds={setFilterIds}
          />
        </Modal>
        <Modal {...stack.register('confirm-action')} centered title="Confirm action">
          <p className="text-sm ">
            Are you sure you want to exit the filter suggestions provided by eyva?
          </p>
          <Group mt="lg" justify="flex-end">
            <Button onClick={() => stack.close('confirm-action')} variant="default">
              Cancel
            </Button>
            <Button
              onClick={() => {
                stack.closeAll()
              }}
            >
              Confirm
            </Button>
          </Group>
        </Modal>
      </Modal.Stack>
      {children({ open: () => stack.open('select-filters') })}
    </>
  )
}

export const AIFilters = () => {
  const [tooltip, setTooltip] = useAtom(toolTipAtom) // initially show tooltip then hide it forever
  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <AIFiltersModal>
      {({ open }) => (
        <Tooltip
          opened={tooltip || showTooltip}
          w={230}
          classNames={{
            tooltip: '!pointer-events-auto',
          }}
          label={
            <div className="space-y-1">
              <div className="flex justify-between">
                <p className="font-semibold">AI Filters</p>
                {tooltip && (
                  <button onClick={() => setTooltip(false)}>
                    <XIcon className="size-3 text-white" />
                  </button>
                )}
              </div>
              <p>
                Use AI filters to help you with the search if you do not know where to find the
                right effects, attributes, and similar.
              </p>
            </div>
          }
          position="bottom"
        >
          <button
            onClick={open}
            className="rounded-md bg-white p-2"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            <SparklesIcon className="size-5 text-primary-900 " />
          </button>
        </Tooltip>
      )}
    </AIFiltersModal>
  )
}
